<!--商品添加-->
<template>
    <div class="mainBox" >
        <!--Header-->
        <div class="handleBox flex flex-jc-sb flex-ai-c">
            <el-page-header @back="goBack" :content="title"></el-page-header>
            <el-button v-if="!isShow" type="primary" size="mini" block @click="submit()">{{submitTitle}}</el-button>
        </div>

        <!--form表单-->
        <div class="formBox shopBox" >
            <el-tabs v-model="activeName" @tab-click="handleTab" style="padding:0 20px;;box-sizing:border-box;">
                <el-tab-pane label="商品信息" name="first"></el-tab-pane>
                <el-tab-pane label="商品详情" name="second"></el-tab-pane>
            </el-tabs>
            <!--商品信息-->
            <el-row style="height: calc(100% - 70px);overflow: auto;">
                <el-form v-if="activeName == 'first'" ref="formAuth" :rules="formRules" :model="formData" label-width="120px" label-position="right" size="small" class="alertForm">
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="formData.name" placeholder="商品名称" autocomplete="off" :disabled="isShow"></el-input>
                    </el-form-item>
                   <!-- <el-form-item label="商品分类" prop="cate_id">
                        <el-select v-model="formData.cate_id" clearable placeholder="商品分类" :disabled="isShow">
                            <el-option-group v-for="group in selectData['category']" :key="group.id" :label="group.cate_name">
                                <el-option v-for="item in group.children" :key="item.id" :label="item.cate_name" :value="item.id"></el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="商品场景" prop="series_id">
						<el-input v-model="formData.series_name" :disabled="true" placeholder="商品名称" autocomplete="off" ></el-input>
                    </el-form-item>
					<!-- <el-form-item label="消耗积分" prop="integral" style="width:50%;" v-if="formData.series_id==38">
					    <el-input v-model="formData.integral" placeholder="消耗积分" autocomplete="off" :disabled="isShow"></el-input>
					</el-form-item> -->
                    <el-form-item label="商品主图" prop="image">
						<upload :imageList="formData.image" :limitNum="limit" :imageWidth="'90px'" :imageHeight="'90px'" :width="'90px'" :height="'90px'" :clickFile="'image'" @upload="uploadFile" :mayEdit="isShow"></upload>
                        <!-- <uploadImage :imageList = "formData.image" :limitNum="limit" :clickFile="'image'" @upload="uploadFile" :mayEdit="isShow"></uploadImage> -->
                    </el-form-item>
                    <el-form-item label="商品展示图" prop="slider_image">
                        <div v-if="formData.slider_image">
                            <!-- <uploadImage :imageList = "formData.slider_image" :limitNum="sliderLimit" :clickFile="'slider_image'" @upload="uploadFile" :mayEdit="isShow"></uploadImage> -->
							<upload :imageList="formData.slider_image" :limitNum="sliderLimit" :imageWidth="'90px'" :imageHeight="'90px'" :width="'90px'" :height="'90px'" :clickFile="'slider_image'" @upload="uploadFile" :mayEdit="isShow"></upload>
						</div>
                    </el-form-item>
                    <el-form-item label="商品规格" prop="rule_id" style="width:100%;margin-right:1%">
                        <el-select v-model="formData.rule_id" clearable placeholder="商品规格" :class="[isShow ? 'noShowClass' : 'isShowClass']" @change="selectSpec" :disabled="isShow">
                            <el-option v-for="val in selectData['rule']" :key="val.id" :label="val.rule_name" :value="val.id"></el-option>
                        </el-select>
                        <el-button type="primary" v-if="!isShow" size="small" @click="addSpecModel()" icon="el-icon-plus">添加规格模板</el-button>
                    </el-form-item>
					<!-- <div v-if="formData.attr && formData.attr.length>0"> -->
					<el-form-item label="" v-for="(item,index) in formData.attr.rule_value" :key="index" style="width:100%">
						<el-row type="flex" align="middle">
							{{item.value}}
							<el-image fit="cover" v-if="!isShow" class="close_img" @click="clearSpec(index)" :src="require('../../assets/close.png')"></el-image>
						</el-row>
						<el-row>
							<el-input v-for="(vitem,vIndex) in item.detailObject" :key="vIndex" v-model="vitem.value" size="mini" :clearable="!isShow ? true : false"  @clear="clearAttribute(index,vIndex)"  autocomplete="off" style="width:100px;margin-right:5px" :disabled="isShow"></el-input>
							<el-button type="primary" v-if="!isShow" size="mini" plain @click="addAttribute(index)">添加属性</el-button>
						</el-row>
					</el-form-item>
					<!-- </div> -->
                    <el-form-item v-if="formData.rule_id && !isBtn && !isShow" style="width:100%">
                        <el-button type="success" size="small" @click="addSpec()" icon="el-icon-plus">添加新规格</el-button>
                        <el-button type="primary" size="small" @click="confirmSpec()" icon="el-icon-check">确定生成</el-button>
                    </el-form-item>
                </el-form>
                <!--添加规格-->
                <el-form v-if="activeName == 'first' && isBtn" ref="specAuth" :rules="specRules" :model="specForm" label-width="120px" label-position="right" size="small" class="alertForm">
                    <el-form-item label="规格名称" prop="specName" style="width:calc(49% - 75px)">
                        <el-input v-model="specForm.specName"  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="规格值" prop="specVal" style="width:calc(50% - 75px)">
                        <el-input v-model="specForm.specVal" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item style="width:150px;margin-right:0;" class="shopAdd_specBtn">
                        <el-button type="primary" plain size="mini" @click="submitSpec()">保存</el-button>
                        <el-button type="primary" plain size="mini" @click="specForm.specName = '';specForm.specVal = '';isBtn = false">取消</el-button>
                    </el-form-item>
                </el-form>

                <!--商品属性-->
                <el-form v-if="activeName == 'first' && isTable" ref="formSpecAuth" style="padding-left:20px;box-sizing:border-box" :rules="formRules" :model="formData" label-width="120px" label-position="top" size="small" class="alertForm">
					
					<!-- <div v-if="formData.scpecs && formData.scpecs.length>0"> -->
					<el-table :data="formData.scpecs.value" border style="width:auto; margin:10px 15px 10px 30px;" size="mini">
					   <el-table-column v-for="col in formData.scpecs.attrHeader" :label="col.title" :prop="col.key" :key="col.key" align="center">
					        <template slot-scope="scope">
					            {{scope.row[col.key]}}
					        </template>
					    </el-table-column>
					    <!-- <el-table-column prop="code" label="条形码" align="center" width="130px">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.code" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column> -->
					    <!-- <el-table-column prop="cost" label="进价" align="center">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.cost" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column>
					    <el-table-column prop="price" label="售价" align="center">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.price" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column>
					    <el-table-column prop="vip_price" label="会员价" align="center">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.vip_price " size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column> -->
					    
						<el-table-column prop="integral" label="消耗积分" align="center">
						    <template slot-scope="scope">
						        <el-input v-model="scope.row.integral" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
						    </template>
						</el-table-column>
					    <el-table-column prop="stock" label="库存" align="center">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.stock" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column>
					    <el-table-column prop="sales" label="拟定销量" align="center">
					        <template slot-scope="scope">
					            <el-input v-model="scope.row.sales" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
					        </template>
					    </el-table-column>
						<el-table-column prop="pre_price" label="预售价" align="center">
						    <template slot-scope="scope">
						        <el-input v-model="scope.row.pre_price" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
						    </template>
						</el-table-column>
						<el-table-column prop="pre_price_first" label="预售定金" align="center">
						    <template slot-scope="scope">
						        <el-input v-model="scope.row.pre_price_first" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
						    </template>
						</el-table-column>
						
						<el-table-column prop="num_limit" label="限购数量" align="center">
						    <template slot-scope="scope">
						        <el-input v-model="scope.row.num_limit" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" :disabled="isShow"></el-input>
						    </template>
						</el-table-column>
						
					    
					    <el-table-column v-if="!isShow" fixed="right" label="操作" width="100" align="center">
					        <template slot-scope="scope">
					            <el-button @click.native.prevent="deleteRow(scope.$index)" type="text" size="small" :disabled="isShow">
					                移除
					            </el-button>
					        </template>
					    </el-table-column>
					</el-table>
					               
					<!-- </div> -->
                   
				 </el-form>

                <!--商品详情-->
                <el-form v-if="activeName == 'second'" ref="formDetailAuth" style="padding:0 20px;box-sizing:border-box" :rules="detail" :model="formData" label-width="120px" label-position="top" size="small" class="alertForm shop_detail">
                    <el-form-item label="商品详情" prop="description" class="shopDes" style="width:100%">
                        <editorBar :editorMain = "detail.description" @change="editorChange" :mayEdit="isShow"></editorBar>
                    </el-form-item>
                </el-form>
            </el-row>

            <!-- 新建框 -->
            <specAdd ref="specAdd" @success="selectList()" :title="specTitle"></specAdd>
        </div>
        

    </div>
</template>
<script>
    // import uploadImage from '@/components/uploadImage';
	import upload from '@/components/upload';
    import editorBar from '@/components/editorBar';
    import specAdd from "@/components/specAdd.vue"; //规格弹框组件
    import {shopReadApi,shopAddSelectApi,shopConfirmApi,shopSubmitApi,editIntegralApi,shopReadDetailApi,shopSaveDetailApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                title:'',//标题
                specTitle:'', //规格标题
                submitTitle:'保存商品',
                filevalue:'',
                activeName:'first', //状态
                limit:1, //商品主图上传个数限制
                sliderLimit:5, //轮播图上传个数限制

                detail:{
                    description:''
                },
                selectData:[],
                specData:[],//商品规格接口所需数据类型
 
                formRules:{
                    name: [{
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'change'
                    }],
					series_id: [{
                        required: true,
                        message: '请选择商品场景',
                        trigger: 'change'
                    }],
                    cate_id: [{
                        required: true,
                        message: '请选择商品分类',
                        trigger: 'change'
                    }],
                    image:[{
                        required: true,
                        message: '请上传商品主图',
                        trigger: 'blur'
                    }],
                    slider_image:[{
                        required: true,
                        message: '请上传商品展示图',
                        trigger: 'blur'
                    }],
                    rule_id:[{
                        required: true,
                        message: '请选择商品规格',
                        trigger: 'change'
                    }],
                    description: [{
                        required: true,
                        message: '请输入商品详情',
                        trigger: 'blur'
                    }],
					
					// integral: [{
     //                    required: true,
     //                    message: '请输入消耗积分',
     //                    trigger: 'blur'
     //                }],
                },
                formData:{
                    id:0,
                    url:'',
                    image:[],
                    slider_image:[],
                    attr:[],
                    scpecs:{},
					series_id:39,
					series_name:'积分商品'
					// integral:''  //新增积分
                },//详情数据
                attrRules:[],
                
                specForm:{},

                isShow:false,//详情or编辑
                isBtn:false,
                isTable:false,

                specRules:{
                    specName: [{
                        required: true,
                        message: '请输入规格名称',
                        trigger: 'change'
                    }],
                    specVal: [{
                        required: true,
                        message: '请输入规格值',
                        trigger: 'change'
                    }],
                }, //规格值必填设置
				
				index:1
            }
        },
        components:{
            // uploadImage, //图片上传
            editorBar, //富文本编辑器
            specAdd, //规格弹框组件
			upload
        },
        mounted() {
            this.init();
        },
        methods: {
            //初始化数据
            init: function(){
                let _this = this;
                
                this.title = this.$route.query.title; //标题
                this.isShow = JSON.parse(this.$route.query.isShow); //编辑or查看
                this.selectList();

                if(_this.$route.query.id){
                    _this.detail.product_id = _this.$route.query.id;
                    _this.getStoreDetail();
                }
            },

            //获取商品基本信息
            getStoreDetail: function(){
                shopReadApi({
                    id:this.$route.query.id,
                    type:0
                }).then(response=>{
                    //初始化商品场景，若不存在则清空
                    response.product.series_id = response.product.series_id == 0 ? '' : response.product.series_id;
                    //初始化关联用户标签，若不存在则清空
                    response.product.label_id = response.product.label_id[0] == 0 ? [] : response.product.label_id;
                    //初始化是否冰冻，若不存在则清空
                    // response.product.frozen = response.product.frozen == 0 ? '' : response.product.frozen.toString();

                    //商品详情
                    this.formData = response.product;
                    this.attrRules = response.rule;


                    //商品图片展示格式
                    let image = [];
                    let slider_image = [];
                    for(let item of response.product.image){
                        image.push({
                            url: item
                        })
                    }
                    for(let slider of response.product.slider_image){
                        slider_image.push({
                            url: slider
                        })
                    }
                    //商品主图
                    this.formData.image =image; 
                    //商品展示图
                    this.formData.slider_image = slider_image;
                    
                    //处于编辑或者查看展示生成商品规格
                    this.isTable = true; 

                    //商品规格数据
                    this.specRuleData(this.formData.rule_id);
                    
                    this.confirmData();
                    this.formData.scpecs = {
                        attrHeader:response.attrHeader,
                        value:response.value,
                        header:response.header
                    };
                })
            },

            //获取商品详情信息
            shopDetail: function(){
                shopReadDetailApi({
                    product_id:this.detail.product_id,
                }).then(response=>{
                    this.detail.description =  response == null ? '' : response.description;
                })
            },

            //下拉列表
            selectList:function(){
                shopAddSelectApi().then(response=>{
                    this.selectData = response;
                })
            },

            //接收上传图片返回数据
            uploadFile: function(file,name) {
				this.formData[name] = file;
                // if(file.image){
                //     this.formData.image = file.image;
                // }else{
                //     this.formData.slider_image = file.slider_image;
                // }
				// console.log(this.formData)
            },

            //tab切换
            handleTab: function(tab){
                this.activeName = tab.name;
                if(tab.name == 'second' && this.detail.product_id){
                    //处于商品详情
                    this.shopDetail();
                    this.submitTitle = '保存商品详情';
                }else if(this.$route.query.id && tab.name == 'first'){
                    //处于商品基本信息
                    this.getStoreDetail();
                    this.submitTitle = '保存商品'
                }
            },

            //删除商品属性
            deleteRow: function(index){
                this.formData.scpecs.value.splice(index,1)
            },

            
            /**
             * 接收富文本子组件输入值
            */
            editorChange: function(value){
                this.detail.description = value;
            },

    /************************************商品规格逻辑操作************************************/
            /**
             * 添加规格模板
            */
            addSpecModel: function(){
                this.$refs.specAdd.open(); //打开规格弹窗
                this.specTitle = '添加规格'; //规格标题
                this.$refs.specAdd.formData = {
                    rule_value:[]
                };
            },
            /**
             * 设置属性
            */
            addAttribute: function(index){
                let idx = this.formData.attr.rule_value[index].detailObject.length - 1;
                let arr = {
                    value:''
                }
                if (this.formData.attr.rule_value[index].detailObject.length == 0) {
                    this.formData.attr.rule_value[index].detailObject.push(arr);
                }else{
                    if (!this.formData.attr.rule_value[index].detailObject[idx].value) {
                        this.$message({
                            message: '商品属性不能有空！',
                            type: 'error'
                        })
                        return;
                    } else {
                        this.formData.attr.rule_value[index].detailObject.push(arr);
                    }
                }
            },
            /**
             * 选择规格
            */
            selectSpec: function(val){
                this.formData.scpecs = {};
                this.isTable = false;
                this.specRuleData(val);
            },

            specRuleData:function(val){
                let ruleAtrr = [];
                //判断编辑or新增
                ruleAtrr = this.detail.product_id ? this.attrRules : this.selectData['rule'];

                ruleAtrr.forEach(ruleObject => {
                    if(ruleObject.id == val){
                        for(let ruleValue of ruleObject.rule_value){
                            //将数组重组为数组对象格式
                            this.$set(ruleValue,'detailObject',[])
                            for(let detail of ruleValue.detail){
                                ruleValue.detailObject.push({
                                    value:detail
                                })
                            }
                        }

                        //若所选商品规格无商品属性，则显示添加商品名称属性输入框
                        if(ruleObject.rule_value.length == 0){
                            this.isBtn = true;
                        }
                       
                        //将所选商品规格所对应的商品属性值赋值到formData
                        this.formData.attr = ruleObject;
                    }
                });
            },
            
            /**
             * 添加规格
            */
            submitSpec: function(){
                this.$refs['specAuth'].validate((valid) => {
                    if (valid) {
                        this.isBtn = false;
                        this.formData.attr.rule_value.push({
                            value:this.specForm.specName,
                            detailObject:[{
                                value:this.specForm.specVal
                            }]
                        });
                        this.specForm.specName = '';
                        this.specForm.specVal = ''
                    }
                })
           },
           /**
             * 添加新规格
            */
            addSpec: function(){
                this.isBtn = true;
            },

            /**
             * 清空选中属性
            */
            clearAttribute: function(index,vIndex){
                this.formData.attr.rule_value[index].detailObject.splice(vIndex,1)
            },

            /**
             * 清空选中规格
            */
            clearSpec: function(index){
                this.formData.attr.rule_value.splice(index,1)
            },

            /**
             * 确定生成商品数据组合
            */
            confirmData:function(){
                this.specData = [];
				
				// if(this.formData.attr && this.formData.attr.length>0){
					for(let ruleValue of this.formData.attr.rule_value){
					    //重置属性规格数据
					    ruleValue.detail = [];
					    for(let detail of ruleValue.detailObject){
					        ruleValue.detail.push(detail.value);
					    }
					    this.specData.push({
					        value:ruleValue.value,
					        detail:ruleValue.detail
					    });
					}
				// }
            },

            /**
             * 确定生成商品
            */
            confirmSpec:function(){
                this.confirmData(); //生成需要传递参数的数据
                shopConfirmApi({
                    id:this.formData.id,
                    attr:this.specData,
                    type:1
                }).then(response=>{
                    this.isTable = true;
                    this.$forceUpdate();  //强制刷新视图层
                    this.formData.scpecs = response;
                })
            },

    /************************************商品规格逻辑操作结束**********************************/

            //保存商品
            submit: function(){
				console.log("this.formData",this.formData)
                if(this.activeName == 'first'){
                    this.$refs['formAuth'].validate((valid) => {
                        if (valid) {
							let ind = 0
							console.log("this.formData.scpecs.value",this.formData.scpecs.value)
                            //添加商品组装数据vip_price
                            for(let item of this.formData.scpecs.value){
                                // this.$set(item,'vip_price',[]); 
                                this.$set(item,'keydata',{}); 
								this.$set(item,'code',this.index++);
                                // for(let head of this.formData.scpecs.header){
                                //     item.keydata[head.slot] = item[head.slot];
                                // }
                                // item.vip_price.push(item.keydata)
                            }
							
							console.log("item",this.formData.scpecs.value)

                            const beginTime = this.formData.scpecs.value.map(value => value.code);
                            console.log(beginTime)
                            //商品规格非空判断
                            for (var x in this.formData.scpecs.value) {
                                // if (this.formData.scpecs.value[x].cost.toString() == '') {
                                //     this.$message.error('进价不得有为空设置');
                                //     return;
                                // }
								 
								if (this.formData.scpecs.value[x].price.toString() == '') {
                                    this.$message.error('售价不得有为空设置');
                                    return;
                                } 
								else if (this.formData.scpecs.value[x].vip_price.toString() == '') {
                                    this.$message.error('会员价不得有为空设置');
                                    return;
                                }
								else  if (this.formData.scpecs.value[x].stock.toString() == '') {
                                    this.$message.error('库存不得有为空设置');
                                    return;
                                }else if (this.formData.scpecs.value[x].sales.toString() == '') {
                                    this.$message.error('拟定销量不得有为空设置');
                                    return;
                                }  else if (this.formData.scpecs.value[x].integral.toString() == '') {
                                    this.$message.error('积分不得有为空设置');
                                    return;
                                } else if (this.formData.scpecs.value[x].pre_price.toString() == '') {
								    this.$message.error('预售价不得有为空设置');
								    return;
								}
								else if (this.formData.scpecs.value[x].pre_price_first.toString() == '') {
								    this.$message.error('预售定金不得有为空设置');
								    return;
								}
                            }

                            editIntegralApi({
                                id:this.formData.id,
                                name:this.formData.name,
                                image:this.formData.image,
                                slider_image:this.formData.slider_image,
                                cate_id:this.formData.cate_id,
                                label_id:this.formData.label_id,
                                series_id:this.formData.series_id,
                                rule_id:this.formData.rule_id,
                                url:this.formData.url,
                                attr:this.specData,
                                value:{
                                    attr:this.specData,
                                    value:this.formData.scpecs.value
                                },
								integral:this.formData.integral,
								
                            }).then(response=>{
                                this.detail.product_id = response.id;
                                this.shopDetail(response.id); //获取详情内容
                                this.activeName = 'second'; //跳转详情
                                this.formData = {};
                            }) 
                        }
                    })

                }else if(this.activeName == 'second'){
                    if(!this.detail.product_id){
                        this.$message.error('请添加商品信息！');
                        return;
                    }
                    shopSaveDetailApi({
                        product_id:this.detail.product_id,
                        description:this.detail.description
                    }).then(()=>{
                        //返回列表
                        this.goBack();
                    })
                }
            },

            //返回上一页
            goBack() {
                this.$router.go(-1)
            },
        },
    }
</script>
<style lang="less">
    .noShowClass{
        width: 100% !important;
        margin-right:0;
    }
    .isShowClass{
        width: calc(98% - 123px) !important;
        margin-right:2%;
    }
    .shopDes{
        .editor{
            width: 375px;
            margin:0;
            .w-e-toolbar .w-e-menu{
                padding: 5px 7px;
            }
        }
    }
</style>